import * as React from 'react';
import * as _ from 'lodash';
import { OfferingDomain } from '../../domain/offering-domain';
import { OfferingListWidgetDisplayOptions } from '../../display-options/offering-list-widget-display-options';
import { translate, TransProps } from 'react-i18next';
import s from './MultiOfferings.st.css';
import { MultiOfferingsTitle } from './MultiOfferingsTitle/MultiOfferingsTitle';
import {
  IMultiOfferingsViewModel,
  MultiOfferingsViewModelFactory,
} from '../../domain/multi-offerings-view-model-factory';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../context/running-environment-context';
import { OfferingList } from './OfferingList/OfferingList';
import {
  ExperimentsProps,
  withExperimentsContext,
} from '../../../Shared/context/experiments-context';
import Categories from './Categories/Categories';
import { CategoryLayoutOptions } from '../../../Shared/appKeys/SettingsKeys';
import { TProp } from '../../../Shared/context/trans-prop';

interface MultiOfferingsProps
  extends TransProps,
    RunningEnvironmentProps,
    TProp,
    ExperimentsProps {
  offeringsDomain: OfferingDomain[];
  displayOptions: OfferingListWidgetDisplayOptions;
  categories: OfferingCategoryDto[];
  onCategoryChanged: Function;
}

interface MultiOfferingsState {
  selectedCategoryIndex: number;
  multiOfferingsViewModel: IMultiOfferingsViewModel;
}

class MultiOfferingsComponent extends React.PureComponent<
  MultiOfferingsProps,
  MultiOfferingsState
> {
  constructor(props) {
    super(props);
    this.onCategorySelected = this.onCategorySelected.bind(this);
    this.state = {
      selectedCategoryIndex: 0,
      multiOfferingsViewModel: this.buildMultiOfferingsViewModel(),
    };
  }

  buildMultiOfferingsViewModel() {
    const {
      t,
      displayOptions,
      categories,
      runningEnvironment,
      experiments,
    } = this.props;
    const { isRTL, isDummyMode, isMobile } = runningEnvironment;
    const multiOfferingsViewModel = MultiOfferingsViewModelFactory.createMultiOfferingsViewModel(
      displayOptions.multiOfferingsDisplayOptions,
      categories,
      t,
      isRTL, //todo - should be removed when yoshi will fix rtl!!!
      isMobile,
      isDummyMode,
      experiments,
    );
    return multiOfferingsViewModel;
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      this.setState({
        multiOfferingsViewModel: this.buildMultiOfferingsViewModel(),
      });
    }
  }

  filterOfferingsByCategory() {
    const { offeringsDomain } = this.props;
    const { multiOfferingsViewModel } = this.state;
    const { categories } = multiOfferingsViewModel.categoriesViewModel;
    const categoryId = categories[this.state.selectedCategoryIndex].id;
    return offeringsDomain.filter(
      offeringDomain => offeringDomain.categoryId === categoryId,
    );
  }

  onCategorySelected(selectedCategoryIndex) {
    this.setState({
      selectedCategoryIndex,
    });
    this.props.onCategoryChanged();
  }

  isFullWidth() {
    const {
      experiments,
      runningEnvironment: { dimensions },
    } = this.props;

    return !dimensions.width && experiments.isFullWidthPaddingEnabled;
  }

  private getOfferingsToDisplay() {
    const { offeringsDomain, experiments } = this.props;
    const { multiOfferingsViewModel, selectedCategoryIndex } = this.state;
    const { layout, isVisible } = multiOfferingsViewModel.categoriesViewModel;
    return !isVisible ||
      (experiments.isCategoryLayoutImprovementMobileEnabled &&
        layout === CategoryLayoutOptions.DROPDOWN &&
        selectedCategoryIndex === 0)
      ? offeringsDomain
      : this.filterOfferingsByCategory();
  }

  render() {
    const { displayOptions, runningEnvironment } = this.props;
    const { isMobile } = runningEnvironment;
    const { multiOfferingsViewModel } = this.state;
    const offeringsToDisplay = this.getOfferingsToDisplay();
    const isFullWidth = this.isFullWidth();

    return (
      <div
        data-hook="multi-offerings-container"
        {...s('root', {
          layout: multiOfferingsViewModel.layout,
          mobile: isMobile,
          fullWidth: isFullWidth,
        })}
      >
        {multiOfferingsViewModel.title.isVisible && (
          <MultiOfferingsTitle
            alignment={multiOfferingsViewModel.title.alignment}
            data-hook="multi-offerings-title"
          >
            {multiOfferingsViewModel.title.text}
          </MultiOfferingsTitle>
        )}

        {multiOfferingsViewModel.categoriesViewModel.isVisible && (
          <Categories
            selectedCategoryIndex={this.state.selectedCategoryIndex}
            categoriesViewModel={multiOfferingsViewModel.categoriesViewModel}
            onCategorySelected={this.onCategorySelected}
          />
        )}
        <OfferingList
          displayOptions={displayOptions}
          offeringsDomain={offeringsToDisplay}
          multiOfferingsViewModel={multiOfferingsViewModel}
        />
      </div>
    );
  }
}

export const MultiOfferings = translate(null, { wait: true })(
  withExperimentsContext(
    withRunningEnvironmentContext(MultiOfferingsComponent),
  ),
);

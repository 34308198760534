import * as React from 'react';
import i18n from '../../i18n';
import { I18nextProvider } from 'react-i18next';
import { MultiOfferings } from '../MultiOfferings/MultiOfferings';
import { OfferingDomain } from '../../domain/offering-domain';
import {
  getDisplayOptions,
  OfferingListWidgetDisplayOptions,
} from '../../display-options/offering-list-widget-display-options';
import {
  MOBILE_MULTI_OFFERINGS_MIN_WIDTH,
  MULTI_OFFERINGS_MIN_WIDTH,
} from './WidgetApp.const';
import {
  BiLoggerContext,
  BiLoggerContextProvider,
} from '../context/bi-logger-context';
import { BiLoggerDriver } from '../../adapters/reporting/bi-logger/bi-logger-driver';
import { ISantaProps } from '@wix/native-components-infra/dist/es/src/types/types';
import {
  CatalogOfferingDto,
  OfferingCategoryDto,
} from '@wix/bookings-uou-domain';
import EmptyStateView from '../EmptyStateView/EmptyStateView';
import { NavigationDriver } from '../../platform/navigation/navigation-driver';
import UserMessage from '@wix/bookings-viewer-ui/dist/src/components/UserMessage/UserMessage';
import {
  OfferingCallbacksContext,
  OfferingCallbacksProvider,
} from '../context/offering-callbacks-context';
import {
  RunningEnvironmentContext,
  RunningEnvironmentContextProvider,
} from '../context/running-environment-context';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { ExperimentsContextProvider } from '../../../Shared/context/experiments-context';
import * as _ from 'lodash';
import { isMobileFromFormFactor } from '../../../Shared/utils';

interface WidgetViewerMainProps extends WidgetAppProps {
  host: ISantaProps;
}

export interface PlatformContext {
  isDummyMode: boolean;
  isRTL: boolean;
  isEditorMode: boolean;
  isPreviewMode: boolean;
  isSSR: boolean;
}

interface WidgetAppProps {
  offerings: CatalogOfferingDto[];
  categories: OfferingCategoryDto[];
  locale: string;
  translations: any;
  settingsData: any;
  setContainerHeight: Function;
  setContainerWidth: Function;
  setContainerDimensions: Function;
  biLoggerDriver: BiLoggerDriver;
  navigationDriver: NavigationDriver;
  canReportLoading: boolean;
  userMessage: {
    shouldDisplayMessage: boolean;
    closeMessage: Function;
  };
  platformContext: PlatformContext;
  appLoadedCallback: Function;
  experiments: any;
  scale: number;
}

export class WidgetApp extends React.PureComponent<WidgetViewerMainProps> {
  private isComponentLoaded = false;
  private readonly widgetAppRef;
  private isExecuteOnLoadedRequired = true;

  constructor(props) {
    super(props);
    this.widgetAppRef = React.createRef();
    this.updateDimensions = _.debounce(this.updateDimensions, 50);
  }

  getRef() {
    return this.widgetAppRef;
  }

  private getMultiOfferingsHeight(): number {
    return _.get(this.getRef().current.getClientRects(), '[0].height', 0);
  }

  private getMultiOfferingsWidth(): number {
    return _.get(this.getRef().current.getClientRects(), '[0].width', 0);
  }

  private isRendered(): boolean {
    return (
      this.getRef().current && this.getRef().current.getClientRects().length
    );
  }

  getHostDimensions() {
    return _.get(this.props, 'host.dimensions');
  }

  private updateDimensions() {
    if (this.isRendered()) {
      const { setContainerWidth } = this.props;

      const servicesWidgetMinWidth = isMobileFromFormFactor(this.props)
        ? MOBILE_MULTI_OFFERINGS_MIN_WIDTH
        : MULTI_OFFERINGS_MIN_WIDTH;

      this.updateHeightIfNeeded();

      if (this.getMultiOfferingsWidth() < servicesWidgetMinWidth) {
        setContainerWidth(servicesWidgetMinWidth);
      }
    }
  }

  private updateHeightIfNeeded() {
    setTimeout(() => {
      const { height } = this.getHostDimensions();

      if (height !== this.getMultiOfferingsHeight()) {
        this.props.setContainerHeight(this.getMultiOfferingsHeight());
      }
    }, 0);
  }

  shouldDisplayOfferings() {
    const { offerings } = this.props;
    return offerings.length > 0;
  }

  componentDidUpdate() {
    this.updateDimensions();
    this.executeOnLoadedCallbacks();
  }

  componentDidMount() {
    this.updateDimensions();
    this.isComponentLoaded = true;
    this.executeOnLoadedCallbacks();
  }

  executeOnLoadedCallbacks() {
    const {
      offerings,
      biLoggerDriver,
      canReportLoading,
      appLoadedCallback,
    } = this.props;
    if (
      canReportLoading &&
      this.isExecuteOnLoadedRequired &&
      this.isComponentLoaded
    ) {
      this.isExecuteOnLoadedRequired = false;
      biLoggerDriver.sendViewerOpened(offerings.length);
      appLoadedCallback();
    }
  }

  createOfferingsDomainInstance = () => {
    return this.props.offerings
      ? this.props.offerings.map(OfferingDomain.fromDto)
      : [];
  };

  render() {
    const {
      locale,
      translations,
      settingsData,
      biLoggerDriver,
      navigationDriver,
      categories,
      userMessage,
      offerings,
      platformContext,
      experiments,
      scale,
    } = this.props;

    const dimensions = this.getHostDimensions();
    const isMobile = isMobileFromFormFactor(this.props);

    const displayOptions: OfferingListWidgetDisplayOptions = getDisplayOptions(
      settingsData,
      isMobile,
    );

    const offeringsDomain = this.createOfferingsDomainInstance();

    const biLoggerContext: BiLoggerContext = {
      biLoggerDriver,
    };

    const offeringCallbacksContext: OfferingCallbacksContext = {
      offeringCallbacks: {
        onOfferingSelected: (offeringId, intent) =>
          navigationDriver.navigateToApp(
            offerings.find(offering => offering.id === offeringId),
            intent,
          ),
      },
    };

    const runningEnvironmentContext: RunningEnvironmentContext = {
      isMobile,
      dimensions,
      scale,
      ...platformContext,
    };

    return (
      <div ref={this.widgetAppRef}>
        <I18nextProvider i18n={i18n(locale, translations)}>
          <BiLoggerContextProvider value={biLoggerContext}>
            <OfferingCallbacksProvider value={offeringCallbacksContext}>
              <ExperimentsContextProvider value={{ experiments }}>
                <RunningEnvironmentContextProvider
                  value={runningEnvironmentContext}
                >
                  <TPAComponentsProvider value={{ mobile: isMobile }}>
                    <UserMessage
                      isOpen={userMessage.shouldDisplayMessage}
                      onRequestClose={userMessage.closeMessage}
                      isMobile={isMobile}
                      message={translations['user-message.not-bookable']}
                      okLabel={translations['user-message.action-ok-label']}
                    />
                    {this.shouldDisplayOfferings() ? (
                      <MultiOfferings
                        offeringsDomain={offeringsDomain}
                        displayOptions={displayOptions}
                        categories={categories}
                        onCategoryChanged={() => this.updateHeightIfNeeded()}
                      />
                    ) : (
                      <EmptyStateView />
                    )}
                  </TPAComponentsProvider>
                </RunningEnvironmentContextProvider>
              </ExperimentsContextProvider>
            </OfferingCallbacksProvider>
          </BiLoggerContextProvider>
        </I18nextProvider>
      </div>
    );
  }
}
